import React from "react";
// import ProductPage from "../ProductsPage/ProductPage.jsx";
import ProductPage from "../../components/Products/ProductPage";

import brands from "../../assets/img/products/hand-tools/brands.png"

import p1 from "assets/img/products/hand-tools/1.jpg";
import p2 from "assets/img/products/hand-tools/2.jpg";
import p3 from "assets/img/products/hand-tools/3.jpg";
import p4 from "assets/img/products/hand-tools/4.jpg";
import p5 from "assets/img/products/hand-tools/5.jpg";
import p6 from "assets/img/products/hand-tools/6.jpg";
import p7 from "assets/img/products/hand-tools/7.jpg";
import p8 from "assets/img/products/hand-tools/8.jpeg";
import p9 from "assets/img/products/hand-tools/9.jpeg";
import p10 from "assets/img/products/hand-tools/10.png";
import p11 from "assets/img/products/hand-tools/11.jpg";
import p12 from "assets/img/products/hand-tools/12.jpg";
import p13 from "assets/img/products/hand-tools/13.jpg";
import p14 from "assets/img/products/hand-tools/14.jpg";
import p15 from "assets/img/products/hand-tools/15.png";
import p16 from "assets/img/products/hand-tools/16.jpg";
import p17 from "assets/img/products/hand-tools/17.jpeg";
import p18 from "assets/img/products/hand-tools/18.jpg";
import p19 from "assets/img/products/hand-tools/19.jpg";
import p20 from "assets/img/products/hand-tools/20.jpeg";
import p21 from "assets/img/products/hand-tools/21.jpg";
import p22 from "assets/img/products/hand-tools/22.jpg";
import p23 from "assets/img/products/hand-tools/23.jpg";

import s1 from "assets/img/products/hand-tools/slider/1.jpg";
import s2 from "assets/img/products/hand-tools/slider/2.jpg";

export default () => (
  <ProductPage prod="Hand Tools" url="hand-tools" brandsImg={brands} products={products} sliders={sliders} />
);

const sliders = [
    { img: s1},
    { img: s2}
]

const products = [
    { name: 'Chalk Reels',      img: p1 },
    { name: 'Riveter',          img: p2 },
    { name: 'Knife',            img: p3 },
    { name: 'Staple',           img: p4 },
    { name: 'Socket',           img: p5 },
    { name: 'Rust',             img: p6 },
    { name: 'Silicon Gun Tools',img: p7 },
    { name: 'Measuring Tape',   img: p8 },
    { name: 'Knipex',           img: p9 },
    { name: 'Allen Key',        img: p10 },
    { name: 'Clamp',            img: p11 },
    { name: 'Bender',           img: p12 },
    { name: 'Staple Gun',       img: p13 },
    { name: 'Vice',             img: p14 },
    { name: 'Wrenches',         img: p15 },
    { name: 'Screw Driver',     img: p16 },
    { name: 'Hand Saws',        img: p17 },
    { name: 'Bucket',           img: p18 },
    { name: 'Hammer',           img: p19 },
    { name: 'Plier',            img: p20 },
    { name: 'Plane',            img: p21 },
    { name: 'Tool Box',         img: p22 },
    { name: 'Ratchet',          img: p23 },
]